// shared/const.ts
const env = {
    IS_PRODUCTION: process.env.NODE_ENV === "production",
    IS_STAGING: process.env.NODE_ENV === "stage",
    IS_DEVELOPMENT: process.env.NODE_ENV === "development",
    IS_TESTING: process.env.NODE_ENV === "test",

    NODE_ENV: process.env.NODE_ENV ?? "development",
    DEPLOY_ENV: process.env.DEPLOY_ENV ?? "development",

    APP_PORT: parseInt(process.env.APP_PORT ?? "3000", 10),
    APP_HOST: process.env.APP_HOST ?? "0.0.0.0",

    API_URL: process.env.API_URL ?? "http://localhost:3000",

    MAX_UPLOAD_FILE_SIZE: 5 * 1024 * 1024 * 1024, // 5GB
    DD_API_KEY: process.env.DD_API_KEY,
    DD_APPLICATION_KEY: process.env.DD_APPLICATION_KEY,
    SENTRY_DSN: process.env.SENTRY_DSN,
} as const;

// Validiere benötigte Umgebungsvariablen // had to be commented out because of the frontend
// const validateEnv = () => {
//     const required = [
//         // "NODE_ENV",
//         // "DEPLOY_ENV",
//         "DB_USER",
//         "DB_NAME",
//         "DB_HOST",
//         "DB_PORT",
//         "DB_PASSWORD",
//         // Füge weitere benötigte Variablen hinzu
//     ];

//     const missing = required.filter((key) => !process.env[key]);

//     if (missing.length > 0) {
//         console.error("Missing required environment variables:", missing);
//         if (env.IS_PRODUCTION || env.IS_STAGING) {
//             throw new Error(
//                 `Missing required environment variables: ${missing.join(", ")}`,
//             );
//         }
//     }
// };

// validateEnv();

export default env;
// export const MAX_UPLOAD_FILE_SIZE = 5 * 1024 * 1024 * 1024; // 5GB

// export const ENV_PRODUCTION = "production";
// export const ENV_TESTING = "test";

// export const ENV_DEVELOPMENT = "development";
// export const ENV_STAGING = "stage";
// export const MAX_UPLOAD_FILE_SIZE = 1000e6; // 1 GB

// // The env object contains constants that are only known during execution.
// // Using an object so that it can be easily mocked from a test.

// const env = {
//     IS_PRODUCTION: process.env.NODE_ENV === ENV_PRODUCTION,
//     IS_TESTING: process.env.NODE_ENV === ENV_TESTING,
//     IS_DEVELOPMENT: process.env.NODE_ENV === ENV_DEVELOPMENT,
//     IS_STAGING: process.env.NODE_ENV === ENV_STAGING,
//     DEV_ORGANIZATION_ID: process.env.DEV_ORGANIZATION_ID,
//     DEPLOY_ENV: process.env.DEPLOY_ENV,
//     NODE_ENV: process.env.NODE_ENV,
//     NODE_ENV_X: process.env.NODE_ENV,
//     API_URL: process.env.API_URL,
// };

// export default env;
