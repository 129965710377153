import {renderAmount} from "./renderAmount";
import {isDefined} from "./typeScriptHelpers";

export const renderAsMoney = (
    amount: number | undefined,
    discountUnit: string | undefined | null,
) => {
    if (discountUnit !== "") {
        return isDefined(amount)
            ? `${renderAmount({amount})} / ${discountUnit}`
            : undefined;
    }

    return isDefined(amount) ? renderAmount({amount}) : undefined;
};
